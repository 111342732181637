import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import BackArrow from "../images/back-arrow.svg"

const CheckEmail = ({ email, submit }) => {
    const [time, setTime] = useState(60)
    const [resend, setResend] = useState(true)

    useEffect(() => {
        let timer = undefined

        if (resend) {
            timer = setInterval(() => {
                setTime(prev => {
                    if (prev <= 0) {
                        setResend(false)
                        return
                    }

                    return +prev - 1
                })
            }, 1000)
        }

        return () => clearInterval(timer)
    }, [resend])

    const onResend = () => {
        setTime(60)
        submit()
        setResend(true)
    }

    return (
        <div className="col paddingy-check-special d-flex flex-column align-items-center">
            <div className="col d-flex flex-column align-items-center text-center">
                <h2>Check your email</h2>
                <p className="mt-1 mt-md-2 mb-0">
                    We sent a password link to <span className="fw-bold">{email}</span>
                </p>
            </div>
            <div className="check-container mt-3 mt-md-4">
                <div className="row gy-3 gy-md-4">
                    <div className="col-12 d-flex justify-content-center">
                        <a
                            target="_blank"
                            rel="noreferrer"
                            href="mailto:"
                            className="button fill text-center light-background w-reset-check-button"
                        >
                            <span>open email app</span>
                        </a>
                    </div>
                    <div className="col-12 text-center">
                        {time ? (
                            <b>{time}s to resend</b>
                        ) : (
                            <button type="button" onClick={onResend} className="resend-button p-0">
                                Didn’t receive the email? <span className="fw-bold">Click to resend</span>
                            </button>
                        )}
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <Link to="/signin" className="text-decoration-none d-flex justify-content-center align-items-center">
                            <img className="back-sign-in" src={BackArrow} alt="Arrow Back" />
                            Back to sign in
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

CheckEmail.propTypes = {
    email: PropTypes.string.isRequired,
    submit: PropTypes.func.isRequired
}

export default CheckEmail
