import React, { useState } from "react"
import fetchQuery from "../utils/fetchQuery"
import CheckEmail from "./checkEmail"
import ForgotForm from "./forgotForm"

const ForgotPassword = () => {
    const [emailField, setEmailField] = useState("")
    const [sent, setSent] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState({})
    const [errorMsg, setErrorMsg] = useState(null)

    const submit = async () => {
        setErrorMsg(null)
        await fetchQuery({
            method: "POST",
            endPoint: "auth/forgotpassword",
            body: {
                emailAddress: emailField
            },
            successCallback: response => {
                if (response === 2000) {
                    setSent(true)
                } else {
                    setErrors({ email: "Email doesn't exist" })
                }
                setIsLoading(false)
            },
            failCallback: () => {
                setIsLoading(false)
                setErrorMsg("Something went wrong. Please reload the page.")
            }
        })
    }

    const onSend = () => {
        const newErrors = {}

        setIsLoading(true)

        if (!emailField.length) {
            newErrors.email = "Required"
        } else {
            let re = /\S+@\S+\.\S+/
            if (!re.test(emailField)) {
                newErrors.email = "Example: name@example.com"
            }
        }

        if (!Object.keys(newErrors).length) {
            submit()
            setErrors({})
        } else {
            setErrors(newErrors)
            setIsLoading(false)
        }
    }

    return (
        <div className="row">
            {!sent ? (
                <ForgotForm
                    emailField={emailField}
                    setEmailField={setEmailField}
                    onSend={onSend}
                    errors={errors}
                    isLoading={isLoading}
                    errorMsg={errorMsg}
                />
            ) : (
                <CheckEmail sent={sent} setSent={setSent} email={emailField} submit={submit} />
            )}
        </div>
    )
}

export default ForgotPassword
