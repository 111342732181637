import React from "react"
import ContentWithBgLayout from "../components/contentWithBgLayout"
import { Footer } from "../components/footer"
import ForgotPassword from "../components/forgotPassword"
import { NavBar } from "../components/header"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ResetPasswordPage = () => {
    return (
        <Layout>
            <Seo title="Reset Password" />
            <NavBar bookNow={false} whiteNav />
            <ContentWithBgLayout>
                <ForgotPassword />
            </ContentWithBgLayout>
            <Footer />
        </Layout>
    )
}

export default ResetPasswordPage
