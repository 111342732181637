import React from "react"
import { Link } from "gatsby"
import BackArrow from "../images/back-arrow.svg"
import PropTypes from "prop-types"

const ForgotForm = ({ errors, isLoading, onSend, emailField, setEmailField, errorMsg }) => {
    return (
        <div className="col paddingy-forgot-special d-flex flex-column align-items-center">
            <div className="col d-flex flex-column align-items-center text-center">
                <h2>Forgot password?</h2>
                <p className="mt-1 mt-md-2">No worries, we will send you reset instructions.</p>
            </div>
            <div className="forgot-form-container">
                <div className="row gy-3 gy-md-4">
                    <div className="col-12">
                        <div className="form-floating">
                            <input
                                type="email"
                                className={`form-control ${errors.email ? "error" : ""} ${emailField ? "color-purple-border" : ""}`}
                                id="email"
                                placeholder="Email"
                                value={emailField}
                                onChange={e => setEmailField(e.target.value)}
                            />
                            <label htmlFor="email" className={`${emailField ? "color-purple" : ""}`}>
                                Email
                            </label>
                            {errors.email && <span className="form-error-message">{errors.email}</span>}
                            {errorMsg && <span className="form-error-message">{errorMsg}</span>}
                        </div>
                    </div>
                    <div className="col-12 d-flex justify-content-center mt-3">
                        <button disabled={isLoading} onClick={onSend} className="button fill light-background w-reset-check-button">
                            <span>reset password</span>
                        </button>
                    </div>
                    <div className="col-12 d-flex justify-content-center">
                        <Link to="/signin" className="text-decoration-none d-flex justify-content-center align-items-center">
                            <img className="back-sign-in" src={BackArrow} alt="Arrow Back" />
                            Back to sign in
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

ForgotForm.propTypes = {
    errors: PropTypes.object.isRequired,
    isLoading: PropTypes.bool.isRequired,
    onSend: PropTypes.func.isRequired,
    emailField: PropTypes.string.isRequired,
    setEmailField: PropTypes.func.isRequired
}

export default ForgotForm
